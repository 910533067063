import React from "react"
import Box from "../box"
import Text from "../text"
import { OtherListItemType } from "./types"

const OtherItem = ({ item }: { item: OtherListItemType }) => {
  return (
    <Box
      grid="1to3"
      css={{
        marginBottom: "$64",
        overflow: "hidden",
        outline: "0",
        transition: "$inOut2",
        textAlign: "start",
        "p, h4": {
          marginY: "0",
        },
        "#hide": {
          opacity: "0",
          transform: "translateY(20px)",
          backgroundColor: "transparent",
          marginTop: "$12",
          padding: "$4",
        },
      }}
    >
      <Box
        as="p"
        grid="itemLeft"
        css={{
          color: "$text_tertiary",
          fontFamily: "$sans",
          fontSize: "$14",
          marginBottom: "$8",
        }}
      >
        {item.dates}
      </Box>
      <Box>
        <Box
          flex={"row"}
          css={{
            gap: "$8",
            alignItems: "baseline",
            fontFamily: "$sans",
            marginBottom: "$8",
          }}
        >
          <Text
            as={"h4"}
            css={{
              fontWeight: "$550",
            }}
          >
            {item.title},
          </Text>
          <Text
            css={{
              color: "$text_tertiary",
            }}
          >
            {item.company}
          </Text>
        </Box>
        <Text css={{ color: "$text_secondary" }}>{item.description}</Text>
      </Box>
    </Box>
  )
}

const Education = ({ list }: { list: OtherListItemType[] }) => {
  return (
    <Box>
      <Box grid="1to3">
        <Box />
        <h2>Other</h2>
      </Box>
      {list.map(item => {
        return <OtherItem item={item} />
      })}
    </Box>
  )
}

export default Education
