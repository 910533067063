import * as React from "react"
import Text from "../text"
import Box from "../box"
import { useEffect, useState } from "react"
import { keyframes } from "@stitches/react"

const Shining = () => {
  const bgAnimation1 = keyframes({
    "0%": {
      backgroundImage: `,
        radial-gradient(at 80% 100%, hsla(240,44%,24%,1) 0px, transparent 50%)`,
    },
    "50%": {
      backgroundImage: `
        radial-gradient(at 15% 82%, hsla(353,44%,24%,1) 0px, transparent 50%)`,
    },
    "100%": {
      backgroundImage: `
        radial-gradient(at 80% 100%, hsla(240,44%,24%,1) 0px, transparent 50%)`,
    },
  })
  const bgAnimation2 = keyframes({
    "0%": {
      backgroundImage: `
        radial-gradient(at 41% 87%, hsla(343,31%,22%,1) 0px, transparent 50%);`,
    },
    "50%": {
      backgroundImage: `
        radial-gradient(at 59% 75%, hsla(245,64%,23%,1) 0px, transparent 50%);`,
    },
    "100%": {
      backgroundImage: `
        radial-gradient(at 41% 87%, hsla(343,31%,22%,1) 0px, transparent 50%);`,
    },
  })
  const bgAnimation3 = keyframes({
    "0%": {
      backgroundImage: `radial-gradient(at 0% 100%, hsla(16,29%,19%,1) 0px, transparent 50%)`,
    },
    "50%": {
      backgroundImage: `radial-gradient(at 92% 98%, hsla(129,29%,19%,1) 0px, transparent 50%)`,
    },
    "100%": {
      backgroundImage: `radial-gradient(at 0% 100%, hsla(16,29%,19%,1) 0px, transparent 50%)`,
    },
  })

  return (
    <>
      <Box
        css={{
          position: "fixed",
          height: "30dvh",
          width: "100dvw",
          bottom: 0,
          left: 0,
          backgroundColor: "$background_primary",
          animation: `${bgAnimation1} 5s cubic-bezier(0.37, 0, 0.63, 1) infinite`,
          zIndex: -1,
        }}
      />
      <Box
        css={{
          position: "fixed",
          height: "30dvh",
          width: "100dvw",
          bottom: 0,
          left: 0,
          backgroundColor: "$background_primary",
          animation: `${bgAnimation2} 5s cubic-bezier(0.37, 0, 0.63, 1) infinite`,
          zIndex: -1,
        }}
      />
      <Box
        css={{
          position: "fixed",
          height: "30dvh",
          width: "100dvw",
          bottom: 0,
          left: 0,
          backgroundColor: "$background_primary",
          animation: `${bgAnimation3} 5s cubic-bezier(0.37, 0, 0.63, 1) infinite`,
          zIndex: -1,
        }}
      />
    </>
  )
}

const TypingText = ({
  text,
  delay = 20,
  paragraph,
}: {
  text: string
  delay?: number
  paragraph: number
}) => {
  const [currentText, setCurrentText] = useState("")

  useEffect(() => {
    const words = text.split(" ")
    const paragraphTimeout = setTimeout(() => {
      words.forEach((word, index) => {
        const timeout = setTimeout(() => {
          setCurrentText(prevText => prevText + " " + word)
        }, index * delay)
        return () => clearTimeout(timeout)
      })
    }, paragraph * delay * 80)
    return () => clearTimeout(paragraphTimeout)
  }, [])

  return <p>{currentText}</p>
}

const GeneratedIntro = () => {
  const messages: string[] = [
    `Kiryl Kavalenka, a Senior Product Designer with 7 years of experience, possesses a comprehensive background in both B2B and B2C environments, having worked across startups and established companies. His versatile skillset, encompassing product research, ideation, analytics, coding, and more, equips him to navigate diverse company stages effectively. Recently, Kiryl took on the responsibility of leading the Growth track in addition to his design duties.`,
    `As a Product Designer, Kiryl has performed various design activities beyond UX, including visual design, motion design, and the development of design systems. He is also dedicated to mentoring and collaborating closely with various teams across different roles.`,
    `From my perspective as AI, I estimate Kiryl to be a talented professional with strong product thinking skills. I consider him an excellent candidate for hire.`,
  ]

  return (
    <Box
      css={{
        width: "100%",
        marginTop: "$64",
        marginBottom: "$third",
      }}
    >
      <Box
        as="h1"
        flex={"column"}
        css={{
          marginBottom: "$32",
          gap: "$8",
          width: "$full",
        }}
      >
        TL;DR
        <Text css={{ color: "$text_tertiary" }}>Summarised by ChatGpt</Text>
      </Box>
      <Text
        id="chat-container"
        size="small"
        css={{
          color: "$text_tertiary",
        }}
      ></Text>
      {messages.map((message, index) => (
        <TypingText key={index} paragraph={index} text={message} />
      ))}
      {/* <Shining /> */}
    </Box>
  )
}

const ResumeIntro = {
  1: <GeneratedIntro />,
  2: (
    <Box>
      <Box
        as="h1"
        flex={"column"}
        css={{
          marginBottom: "$32",
          gap: "$8",
          width: "$full",
        }}
      >
        Kiryl Kavalenka
        <Text css={{ color: "$text_tertiary" }}>Senior Product Designer</Text>
      </Box>
      <Box>
        <Text size="large">
          Hey, I’m a designer with 7 years of experience.
        </Text>
        <Text size="large">
          Currently, I’m a Senior Product Designer at StarOfService with a focus
          on product growth.
        </Text>
        <Text size="large">
          I'm used to a Swiss army knife role—besides the design activities, I
          dive deep into data analytics and research, pitch ideas and write a
          bit of code to tailor elegant and efficient solutions for complex
          systems.
        </Text>
      </Box>
    </Box>
  ),
  3: (
    <Box>
      <Box
        as="h1"
        flex={"column"}
        css={{
          marginBottom: "$32",
          gap: "$8",
          width: "$full",
        }}
      >
        Kiryl Kavalenka
        <Text css={{ color: "$text_tertiary" }}>Senior Product Designer</Text>
      </Box>
      <Box>
        <Text size="large">
          Designer with 7 years of experience in diverse environments, including
          early-stage startups and established companies in B2B and B2C models.
        </Text>
        <Text size="large">
          This experience has allowed me to develop a versatile skillset
          encompassing end-to-end product design, product acumen,
          cross-functional collaboration and leadership.
        </Text>
        <Text size="large">
          My design experience spans from ideation to execution. Having honed my
          skills in building products from the ground up, I now focus on product
          growth, utilising a data-driven approach, emphasising user feedback to
          create user-centric and impactful products.
        </Text>
      </Box>
    </Box>
  ),
}

export default ResumeIntro
