import React from "react"

import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { useMixpanel } from "gatsby-plugin-mixpanel"

import Box from "../box"
import Text from "../text"
import { LinkIcon, SandGlassIcon } from "../icons"

import { PhotoType, ProjectType } from "./types"

const ProjectLink = ({
  project,
  covers,
  large = false,
}: {
  project: ProjectType
  covers: PhotoType[]
  large?: boolean
}) => {
  const cover =
    project.cover && covers
      ? covers.find(node => node.name === project.cover)
      : null

  const mixpanel = useMixpanel()

  const Cover = () => {
    if (cover) {
      return (
        <Box
          css={{
            display: "grid",
            "@bp4": {
              width: "$128",
              height: "96px",
            },
          }}
        >
          <GatsbyImage
            alt={`${project.title} cover image`}
            image={getImage(cover) as IGatsbyImageData}
            style={{ width: "100%", height: "100%", gridColumn: 1, gridRow: 1 }}
            id="cover-image"
          />
          {project.inProgress && (
            <Box
              css={{
                gridColumn: 1,
                gridRow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#060C0ECC",
                zIndex: "0",
                "@bp4": {
                  height: "96px",
                  width: "$128",
                },
              }}
            >
              <Text
                css={{
                  fontFamily: "$sans",
                  fontWeight: "$550",
                  color: "$text_secondary",
                  marginBottom: 0,
                }}
              >
                Coming soon
              </Text>
            </Box>
          )}
        </Box>
      )
    } else {
      return (
        <Box
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "@bp4": {
              height: "96px",
              width: "$128",
            },
          }}
        >
          <Text>Coming soon</Text>
        </Box>
      )
    }
  }

  return (
    <Box
      as={!project.inProgress ? "a" : "div"}
      onClick={() => {
        mixpanel.track("Resume: Open project", {
          to: project.title,
        })
      }}
      css={{
        gridColumn: large ? "span 2" : "span 1",
        display: "grid",
        gridTemplateRows: large ? "480px 1fr" : "240px 1fr",
        gridTemplateColumns: "1fr",
        rowGap: 0,
        textDecoration: "none",
        borderRadius: "$8",
        border: "1px solid $background_secondary",
        overflow: "hidden",
        transition: "$inOut1",
        width: "$full",
        boxSizing: "border-box",
        backgroundColor: "$background_primary",

        "&:hover": !project.inProgress
          ? {
              border: "1px solid $background_tertiary",
              backgroundColor: "$background_secondary",
              "#icon-box": {
                backgroundColor: "$background_tertiary",
              },
              "#cover-image": {
                transform: "scale(1.05)",
              },
            }
          : {},
        "#icon-box, #cover-image": {
          transition: "$inOut1",
        },

        "@bp4": {
          gridTemplateRows: "1fr",
          gridTemplateColumns: " 128px 1fr",
          gridColumn: "span 1",

          "&:hover": !project.inProgress
            ? {
                "#cover-image": {
                  transform: "scale(1.1)",
                },
              }
            : {},
        },
      }}
      href={project.link}
    >
      <Cover />
      <Box flex="row">
        <Box
          id="icon-box"
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "$48",
            backgroundColor: "$background_secondary",

            "@bp4": {
              display: "none",
            },
          }}
        >
          {project.inProgress ? (
            <SandGlassIcon size={20} />
          ) : (
            <LinkIcon size={20} />
          )}
        </Box>
        <Box
          flex="column"
          css={{
            padding: "$8 $16",

            "@bp4": {
              justifyContent: "center",
            },
          }}
        >
          <Text
            css={{
              color: "$text_tertiary",
              fontFamily: "$sans",
              fontSize: "$12",
              marginBottom: 0,
            }}
          >
            {project.type}
            {project.inProgress && ` (In progress)`}
          </Text>
          <Text
            css={{
              fontSize: "$14",
              marginBottom: "4px",
            }}
          >
            {project.title}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default ProjectLink
