import React from "react"
import Box from "../box"
import Text from "../text"
import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { LinkIcon } from "../icons"

const SelectedArticles = ({ list }) => {
  return (
    <Box grid="1to3">
      <Box />
      <Box>
        <h2>Selected articles</h2>
        <Box
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "$16",
            rowGap: "$16",

            "@bp4": {
              gridTemplateColumns: "1fr",
              rowGap: "$8",
            },
          }}
        >
          {list.map(item => {
            return (
              <Box
                as={"a"}
                css={{
                  display: "grid",
                  gridTemplateRows: "240px 1fr",
                  gridTemplateColumns: "1fr",
                  rowGap: 0,
                  textDecoration: "none",
                  borderRadius: "$8",
                  border: "1px solid $background_secondary",
                  overflow: "hidden",
                  transition: "$inOut1",
                  width: "$full",
                  boxSizing: "border-box",
                  backgroundColor: "$background_primary",

                  "&:hover": {
                    border: "1px solid $background_tertiary",
                    backgroundColor: "$background_secondary",
                    "#icon-box": {
                      backgroundColor: "$background_tertiary",
                    },
                    "#cover-image": {
                      transform: "scale(1.1)",
                    },
                  },
                  "#icon-box, #cover-image": {
                    transition: "$inOut1",
                  },

                  "@bp4": {
                    gridTemplateRows: "1fr",
                    gridTemplateColumns: " 128px 1fr",
                  },
                }}
                href={item.fields.slug}
              >
                <Box
                  css={{
                    height: "$full",
                    overflow: "hidden",
                    "@bp4": {
                      height: "96px",
                    },
                  }}
                >
                  <Box
                    id="cover-image"
                    css={{
                      height: "$full",
                      overflow: "hidden",
                      "@bp4": {
                        height: "96px",
                      },
                    }}
                  >
                    <GatsbyImage
                      alt={`${item.title} cover image`}
                      image={
                        getImage(
                          item.frontmatter.cover.childrenImageSharp[0]
                        ) as IGatsbyImageData
                      }
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Box>
                <Box
                  css={{
                    display: "grid",
                    gridTemplateColumns: "$48 1fr",
                    height: "$full",

                    "@bp4": {
                      gridTemplateColumns: "1fr",
                    },
                  }}
                >
                  <Box
                    id="icon-box"
                    css={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "$48",
                      backgroundColor: "$background_secondary",

                      "@bp4": {
                        display: "none",
                      },
                    }}
                  >
                    <LinkIcon size={20} />
                  </Box>
                  <Box
                    flex="column"
                    css={{
                      padding: "$8 $16",

                      "@bp4": {
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Text
                      css={{
                        color: "$text_tertiary",
                        fontFamily: "$sans",
                        fontSize: "$12",
                        marginBottom: "$4",
                      }}
                    >
                      {item.frontmatter.date}
                    </Text>
                    <Text
                      css={{
                        fontSize: "$14",
                        marginBottom: "0",
                      }}
                    >
                      {item.frontmatter.title}
                    </Text>
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
        <Link to="/writings">
          <Text css={{ fontFamily: "$sans", marginTop: "$24" }}>
            View all articles
          </Text>
        </Link>
      </Box>
    </Box>
  )
}

export default SelectedArticles
