import React from "react"
import Box from "../box"
import Text from "../text"
import { JobTagType, JobType, JobsType, PhotoType } from "./types"
import ProjectLink from "./projectLink"
import Divider from "../divider"

const Tags = ({ tags }: { tags?: JobTagType[] }) => {
  if (tags) {
    return (
      <Box
        flex={"row"}
        css={{ gap: "$4", marginBottom: "$16", marginTop: "$4" }}
      >
        {tags.map(tag => {
          return (
            <Box
              css={{
                backgroundColor: "$background_secondary",
                padding: "0 $8 $2",
                fontFamily: "$sans",
                fontSize: "$12",
                fontWeight: "$550",
                color: "$text_secondary",
                borderRadius: "$4",
              }}
            >
              {tag}
            </Box>
          )
        })}
      </Box>
    )
  }
}

const Details = ({
  points,
  isExpanded,
  divider,
}: {
  points?: string[]
  isExpanded: boolean
  divider: boolean
}) => {
  if (points) {
    return (
      <Box
        css={{
          maxHeight: isExpanded ? "1000px" : "0",
          opacity: isExpanded ? "1" : "0",
          overflow: "hidden",
          transition: "$inOut2",
          marginY: "$16",
          "p, li": { color: "$text_secondary", fontSize: "$16" },
        }}
      >
        <Box
          as="ul"
          css={{
            marginY: "0",
            paddingY: "$8",
            paddingLeft: "$16",
            li: {
              marginBottom: "$16",
              lineHeight: "1.6",
            },
          }}
        >
          {points.map(point => {
            return <li>{point}</li>
          })}
        </Box>
        {divider ? (
          <Divider
            direction="horisontal"
            css={{
              transition: "$inOut2",
            }}
          />
        ) : null}
      </Box>
    )
  }
}

const Job = ({
  job,
  active,
  covers,
}: {
  job: JobType
  active: 1 | 2 | 3
  covers: PhotoType[]
}) => {
  return (
    <Box
      grid="1to3"
      css={{
        marginBottom: "$64",
        overflow: "hidden",
        outline: "0",
        transition: "$inOut2",
        textAlign: "start",
        "p, h4": {
          marginY: "0",
        },
        "#hide": {
          opacity: "0",
          transform: "translateY(20px)",
          backgroundColor: "transparent",
          marginTop: "$12",
          padding: "$4",
        },
      }}
    >
      <Box
        as="p"
        grid="itemLeft"
        css={{
          color: "$text_tertiary",
          fontFamily: "$sans",
          fontSize: "$14",
          marginBottom: "$8",
        }}
      >
        {job.dates}
      </Box>
      <Box
        css={{
          "@bp1": {
            paddingTop: "$4",
          },
        }}
      >
        <Box
          flex={"column"}
          css={{
            gap: "$2",
            alignItems: "baseline",
            fontFamily: "$sans",
            marginBottom: "$12",
          }}
        >
          <Text
            as={"h4"}
            size="large"
            css={{
              fontWeight: "$550",
            }}
          >
            {job.role}
          </Text>
          <Text
            size="small"
            css={{
              color: "$text_tertiary",
              textDecorationColor: "$text_tertiary",
              fontFamily: "$serif",
            }}
          >
            {`at `}
            {job.companyLink ? (
              <Text
                as={"a"}
                href={job.companyLink}
                target="_blank"
                size="small"
              >
                {job.company}
              </Text>
            ) : (
              job.company
            )}
          </Text>
        </Box>
        {/* <Tags tags={job.tags} /> */}
        <Text size={"medium"} css={{ color: "$text_secondary" }}>
          {job.description[active]}
        </Text>
        {job.details && (
          <Details
            points={job.details}
            isExpanded={active === 3}
            divider={Boolean(job.projects)}
          />
        )}
        {job.projects && active === 3 ? (
          <Box
            css={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "$16",
              rowGap: "$16",
              marginTop: "$8",

              "@bp4": {
                gridTemplateColumns: "1fr",
                rowGap: "$8",
              },

              "a:has(:hover) a:not(:hover)": {
                opacity: "70%",
              },
            }}
          >
            {job.projects.map(project => {
              return <ProjectLink project={project} covers={covers} />
            })}
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

const ExperienseList = ({
  list,
  active,
  covers,
}: {
  list: JobsType
  active: 1 | 2 | 3
  covers: PhotoType[]
}) => {
  return (
    <Box>
      <Box grid="1to3">
        <Box />
        <h2>Professional Experience</h2>
      </Box>
      {list.map(job => {
        return <Job job={job} active={active} covers={covers} />
      })}
    </Box>
  )
}

export default ExperienseList
