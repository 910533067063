import * as React from "react"
import { useState } from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Box from "../../components/box"
import jobsList from "../../components/resume/jobsList"
import otherList from "../../components/resume/otherList"
import Other from "../../components/resume/other"
import ExperienseList from "../../components/resume/experienseList"
import DetailsSwitch from "../../components/resume/detailsSwitch"
import ResumeIntro from "../../components/resume/resumeIntro"

import { PhotoType } from "../../components/resume/types"
import SelectedArticles from "../../components/resume/selectedArticles"
import MediaSection from "../../components/resume/mediaSection"

type frontmatterType = {
  date: string
  description?: string
  title: string
  cover: PhotoType
}
type nodeType = {
  frontmatter: frontmatterType
}

type articleType = {
  edges: {
    node: nodeType[]
  }
}

type DataProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allFile: {
    nodes: PhotoType[]
  }
  abtests: articleType
  amateur: articleType
}

const NotFoundPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const [active, setActive] = useState<1 | 2 | 3>(2)

  return (
    <Layout title={siteTitle} width="960px" contactSection>
      <Seo title="Resume" />
      <Box
        css={{
          width: "$full",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",

          section: {
            width: "$full",
            marginBottom: "$96",
          },
          h2: {
            marginBottom: "$32",
          },
        }}
      >
        <Box
          flex="column"
          css={{
            minHeight: "$fullMinusHeader",
            justifyContent: "center",

            boxSizing: "border-box",
            width: "$full",
          }}
        >
          <Box grid="1to3">
            <Box />
            {ResumeIntro[active]}
          </Box>
        </Box>
        {active === 2 && (
          <section>
            <MediaSection
              list={[...jobsList, ...otherList]}
              covers={data.allFile.nodes}
            />
          </section>
        )}
        {active != 1 && (
          <section>
            <ExperienseList
              list={jobsList}
              active={active}
              covers={data.allFile.nodes}
            />
          </section>
        )}

        {active != 1 && (
          <section>
            <Other list={otherList} />
          </section>
        )}

        {active != 1 && (
          <section>
            <SelectedArticles
              list={[data.abtests.edges[0].node, data.amateur.edges[0].node]}
            />
          </section>
        )}
      </Box>
      <DetailsSwitch active={active} setActive={setActive} />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { relativeDirectory: { eq: "portfolio-images/covers" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            blurredOptions: { width: 240 }
            width: 720
            placeholder: BLURRED
          )
        }
      }
    }
    abtests: allMdx(
      filter: { frontmatter: { title: { eq: "Understanding A/B Testing" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            cover {
              childrenImageSharp {
                gatsbyImageData(
                  blurredOptions: { width: 240 }
                  width: 1280
                  placeholder: BLURRED
                )
              }
            }
            link
          }
        }
      }
    }
    amateur: allMdx(
      filter: {
        frontmatter: {
          title: {
            eq: "The path of a generalist or the joy of being an amateur"
          }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            cover {
              childrenImageSharp {
                gatsbyImageData(
                  blurredOptions: { width: 240 }
                  width: 1280
                  placeholder: BLURRED
                )
              }
            }
            link
          }
        }
      }
    }
  }
`
