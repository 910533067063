import React from "react"
import Box from "../box"
import ProjectLink from "./projectLink"
import { JobType, OtherListItemType, PhotoType, ProjectType } from "./types"

const MediaSection = ({
  list,
  covers,
}: {
  list: (JobType | OtherListItemType)[]
  covers: PhotoType[]
}) => {
  const sortList = () => {
    let sortedCasesList: ProjectType[] = []
    let sortedOtherList: ProjectType[] = []

    list.forEach(item => {
      item.projects &&
        item.projects.forEach(project => {
          if (project.type === "Case study") {
            sortedCasesList.push(project)
          } else {
            sortedOtherList.push(project)
          }
        })
    })
    return [...sortedCasesList, ...sortedOtherList]
  }

  let sortedList: ProjectType[] = sortList()

  return (
    <Box>
      <Box grid="1to3">
        <Box />
        <Box>
          <h2>Projects</h2>

          <Box
            css={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "$16",
              rowGap: "$16",
              marginTop: "$8",

              "@bp4": {
                gridTemplateColumns: "1fr",
                rowGap: "$8",
              },

              "a:has(:hover) a:not(:hover)": {
                opacity: "70%",
              },
            }}
          >
            {sortedList.map(project => {
              return (
                <ProjectLink
                  project={project}
                  covers={covers}
                  large={!project.inProgress && project.type === "Case study"}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MediaSection
