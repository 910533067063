import * as React from "react"
import { Dispatch, SetStateAction, useEffect, useState } from "react"

import Box from "../box"
import Text from "../text"

import { useMixpanel } from "gatsby-plugin-mixpanel"

import "./styles.css"

const SwitchButton = ({
  children,
  active,
  index,
  setActive,
  updateInsturctions,
}: {
  children: React.ReactNode
  active: 1 | 2 | 3
  index: 1 | 2 | 3
  setActive: Dispatch<SetStateAction<1 | 2 | 3>>
  updateInsturctions: (active: 1 | 2 | 3) => void
}) => {
  const mixpanel = useMixpanel()

  return (
    <Box
      as="button"
      css={{
        border: "0",
        borderRadius: "$round",
        boxSizing: "border-box",
        padding: "$16 $24",
        fontFamily: "$sans",
        fontSize: "$12",
        fontWeight: "$550",
        gridArea: "item" + index,
        backgroundColor: "transparent",
        color: index === active ? "$background_primary" : "$text_tertiary",
        zIndex: 100,
        transition: "$inOut1",
        cursor: "pointer",
        p: {
          color: index === active ? "$background_primary" : "$text_tertiary",
          transition: "$inOut1",
        },

        "&:hover": {
          color: index === active ? "$background_primary" : "$text_primary",
          backgroundColor: "$background_primary" + "4D",
        },
      }}
      onClick={() => {
        setActive(index)
        updateInsturctions(index)
        mixpanel.track("Details switch click", {
          to: { index },
        })
      }}
    >
      {children}
    </Box>
  )
}

const DetailsSwitch = ({
  active,
  setActive,
}: {
  active: 1 | 2 | 3
  setActive: Dispatch<SetStateAction<1 | 2 | 3>>
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(false)
  const [prevScrollY, setPrevScrollY] = useState(0)
  const [bottomOffset, setBottomOffset] = useState(9999)
  const [fullWidth, setFullWidth] = useState(false)
  const [instruction, setInstruction] = useState(true)

  const offset = 144

  const updateInsturctions = (active: 1 | 2 | 3) => {
    const currentScrollY = window.scrollY
    if (currentScrollY < offset && active === 2) {
      setInstruction(true)
    } else {
      setInstruction(false)
    }
  }

  useEffect(() => {
    setBottomOffset(
      document.documentElement.scrollHeight - window.innerHeight - offset
    )
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      if (currentScrollY > prevScrollY + 16 && currentScrollY < bottomOffset) {
        setIsHidden(true)
      } else if (
        currentScrollY < prevScrollY - 16 ||
        currentScrollY < offset ||
        currentScrollY > bottomOffset
      ) {
        setIsHidden(false)
      }

      if (currentScrollY > bottomOffset) {
        setFullWidth(true)
      } else {
        setFullWidth(false)
      }

      if (currentScrollY < offset && active === 2) {
        setInstruction(true)
      } else {
        setInstruction(false)
      }

      setPrevScrollY(currentScrollY)
    }

    console.log("active? " + active)

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [prevScrollY, setActive])

  const getTranslateValue = () => {
    switch (active) {
      case 1:
        return "translateX(-100%)"
      case 2:
        return "translateX(0)"
      case 3:
        return "translateX(100%)"
      default:
        return "translateX(0)"
    }
  }

  const setMobilePosition = () => {
    if (isHidden) {
      return "-40px"
    } else if (fullWidth) {
      return "0"
    } else {
      return "$24"
    }
  }

  const setPadding = () => {
    if (instruction) {
      return "$8 $4"
    } else if (fullWidth) {
      return "0"
    } else {
      return "$4"
    }
  }

  const setMobilePadding = () => {
    if (instruction) {
      return "0 $4 $8"
    } else if (fullWidth) {
      return "$12 $20"
    } else {
      return "$4"
    }
  }

  const setMobileRounding = () => {
    if (instruction) {
      return "0 0 $32 $32"
    } else if (fullWidth) {
      return 0
    } else {
      return "$32"
    }
  }

  return (
    <Box
      className={instruction ? "box" : ""}
      css={{
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
        bottom: isHidden ? "-40px" : "$32",
        opacity: isHidden ? "0" : "1",
        borderRadius: "31px",
        maxWidth: "$full",
        transition: "$inOut1",

        "@bp1": {
          width: fullWidth ? "100vw" : "calc(100vw - 48px)",
          bottom: setMobilePosition(),
          borderRadius: fullWidth ? "0" : "40px",
          padding: setPadding(),
        },
      }}
    >
      <Text
        css={{
          opacity: instruction ? "1" : "0",
          transform: instruction ? "translateY(0)" : "translateY(-8px)",
          maxHeight: instruction ? "$32" : "0",
          backgroundColor: "$background_secondary",
          transition: "$inOut1",
          marginTop: instruction ? "1px" : "0",
          marginBottom: "0",
          marginX: "1px",
          borderRadius: "$32 $32 0 0",
          width: "calc(100%-2px)",
          textAlign: "center",
          padding: instruction ? "$8" : "0",
          fontFamily: "$sans",
          fontSize: "$12",
          fontWeight: "$550",
          color: "$text_tertiary",
        }}
      >
        You can choose the level of details:
      </Text>
      <Box
        css={{
          display: "grid",
          gridTemplateColumns: "[item1] 1fr [item2] 1fr [item3] 1fr",
          boxSizing: "border-box",
          padding: instruction ? "0 $4 $8" : "$4",
          justifyContent: "stretch",
          transition: "$inOut1",
          backgroundColor: instruction
            ? "$background_secondary"
            : "$background_secondary",
          borderRadius: instruction ? "0 0 $32 $32" : "$32",
          margin: instruction ? "0 1px 1px" : "0",

          "@bp1": {
            borderRadius: setMobileRounding(),
            padding: setMobilePadding(),
          },
        }}
      >
        <SwitchButton
          active={active}
          updateInsturctions={updateInsturctions}
          setActive={setActive}
          index={1}
        >
          TL;DR
        </SwitchButton>
        <SwitchButton
          active={active}
          updateInsturctions={updateInsturctions}
          setActive={setActive}
          index={2}
        >
          Brief
        </SwitchButton>
        <SwitchButton
          active={active}
          updateInsturctions={updateInsturctions}
          setActive={setActive}
          index={3}
        >
          Detailed
        </SwitchButton>
        <Box
          css={{
            backgroundColor: "$text_secondary",
            gridArea: "item2",
            transform: getTranslateValue(),
            width: "$full",
            height: "$full",
            transition: "$inOut2",
            borderRadius: "$round",
          }}
        />
      </Box>
    </Box>
  )
}

export default DetailsSwitch
