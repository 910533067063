import { OtherListItemType } from "./types"

const otherList: OtherListItemType[] = [
  {
    dates: "2021",
    title: "Simulator for Learning Growth",
    company: "GoPractice",
    description: "Product management and product analytics course.",
  },
  {
    dates: "2021",
    title: `"Belarus Digital Future" Hackathon)`,
    description: "Participated as Project Coordinator, Designer, Developer",
    company: "1st place",
    projects: [
      {
        title: "Asveta",
        link: "/resume/projects/asveta",
        type: "Case study",
        cover: "asveta",
        inProgress: true,
      },
    ],
  },
  {
    dates: "2018",
    title: `“Tech for Life” Hackathon`,
    description: "Participated as a Product Designer",
    company: "1st place",
  },
  {
    dates: "2018",
    title: `DEP Hackathon Special Prize: Best solution for Schneider Electric`,
    description: "Participated as a Product Designer",
  },
]

export default otherList
